import {Component, Input, ViewChild} from '@angular/core';
import {CommonModule} from "@angular/common";
import {ProfileAvatarComponent} from "../../../../../../uikit/profile/profile-avatar/profile-avatar.component";
import {UserNameComponent} from "../../../../../../uikit/user-name/user-name.component";
import {IconsComponent} from "../../../../../../uikit/icons/icons.component";
import {AppNotificationFrom} from "../../notification.component";
import {
  INotificationsChangedSubscriptionFromPaidToFreeNotification
} from "@prestinly/core-lib/lib/types/notifications/subscription/changed-subscription-from-paid-to-free.notification.interface";
import {TranslocoModule} from "@ngneat/transloco";

@Component({
  selector: 'app-n-sub-change-to-free',
  standalone: true,
  imports: [
    CommonModule,
    ProfileAvatarComponent,
    UserNameComponent,
    IconsComponent,
    TranslocoModule
  ],
  templateUrl: './n-sub-change-to-free.component.html',
  styleUrl: './n-sub-change-to-free.component.scss'
})
export class NSubChangeToFreeComponent extends AppNotificationFrom {
  @ViewChild('notification') declare notification;
  @Input('data') declare data: INotificationsChangedSubscriptionFromPaidToFreeNotification;
}
