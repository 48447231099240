"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ENotificationAction = void 0;
var ENotificationAction;
(function (ENotificationAction) {
    ENotificationAction["KYC_APPROVED"] = "KYC_APPROVED";
    ENotificationAction["KYC_REJECTED"] = "KYC_REJECTED";
    ENotificationAction["KYC_EXPIRED"] = "KYC_EXPIRED";
    ENotificationAction["NEW_SUBSCRIBER"] = "NEW_SUBSCRIBER";
    ENotificationAction["CHANGED_SUBSCRIPTION_FROM_FREE_TO_PAID"] = "CHANGED_SUBSCRIPTION_FROM_FREE_TO_PAID";
    ENotificationAction["CHANGED_SUBSCRIPTION_FROM_PAID_TO_FREE"] = "CHANGED_SUBSCRIPTION_FROM_PAID_TO_FREE";
    ENotificationAction["CHANGED_SUBSCRIPTION_FROM_PAID_TO_PAID"] = "CHANGED_SUBSCRIPTION_FROM_PAID_TO_PAID";
    ENotificationAction["DONATED"] = "DONATED";
    ENotificationAction["POST_PURCHASED"] = "POST_PURCHASED";
    ENotificationAction["MESSAGE_PURCHASED"] = "MESSAGE_PURCHASED";
    ENotificationAction["IN_POST_TAG"] = "IN_POST_TAG";
    ENotificationAction["IN_POST_COMMENT_TAG"] = "IN_POST_COMMENT_TAG";
    ENotificationAction["NEW_POST"] = "NEW_POST";
    ENotificationAction["NEW_POST_COMMENT"] = "NEW_POST_COMMENT";
    ENotificationAction["NEW_MESSAGES"] = "NEW_MESSAGES";
})(ENotificationAction || (exports.ENotificationAction = ENotificationAction = {}));
