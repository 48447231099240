import {Component, inject, Input, ViewChild} from '@angular/core';
import {CommonModule} from "@angular/common";
import {ProfileAvatarComponent} from "../../../../../../uikit/profile/profile-avatar/profile-avatar.component";
import {UserNameComponent} from "../../../../../../uikit/user-name/user-name.component";
import {IconsComponent} from "../../../../../../uikit/icons/icons.component";
import {RouterModule} from "@angular/router";
import {AppNotificationFrom} from "../../notification.component";
import {
  INotificationsNewPostNotification
} from "@prestinly/core-lib/lib/types/notifications/content/new-post.notification.interface";
import {TranslocoModule} from "@ngneat/transloco";

@Component({
  selector: 'app-n-new-post',
  standalone: true,
  imports: [
    CommonModule,
    ProfileAvatarComponent,
    UserNameComponent,
    IconsComponent,
    RouterModule,
    TranslocoModule,
  ],
  templateUrl: './n-new-post.component.html',
  styleUrl: './n-new-post.component.scss'
})
export class NNewPostComponent extends AppNotificationFrom {
  @ViewChild('notification') declare notification;

  @Input('data') declare data: INotificationsNewPostNotification;

  get link() {
    return `/profile/${this.data.fromUserId}/post/${this.data.postId}`;
  }
}
