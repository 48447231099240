import {Component, Input, ViewChild} from '@angular/core';
import {AppNotificationFrom} from "../../notification.component";
import {CommonModule} from "@angular/common";
import {ProfileAvatarComponent} from "../../../../../../uikit/profile/profile-avatar/profile-avatar.component";
import {IconsComponent} from "../../../../../../uikit/icons/icons.component";
import {UserNameComponent} from "../../../../../../uikit/user-name/user-name.component";
import {
  INotificationsDonatedNotification
} from "@prestinly/core-lib/lib/types/notifications/purchases/donated.notification.interface";
import {TranslocoModule} from "@ngneat/transloco";

@Component({
  selector: 'app-n-donated',
  standalone: true,
  imports: [
    CommonModule,
    ProfileAvatarComponent,
    IconsComponent,
    UserNameComponent,
    TranslocoModule
  ],
  templateUrl: './n-donated.component.html',
  styleUrl: './n-donated.component.scss'
})
export class NDonatedComponent extends AppNotificationFrom {
  @ViewChild('notification') declare notification;
  @Input('data') declare data: INotificationsDonatedNotification;
}
