<ng-container *ngTemplateOutlet="outlet == 'list' ? list : toast"></ng-container>
<ng-template #list>
  <div class="notification" #notification>
    <div class="notification_status" [ngClass]="{'isRead': data.isReadByUser}"></div>
    <app-profile-avatar size="chat" [url]=""></app-profile-avatar>
    <div class="notification_body">
      <div class="notification_body-text">
        <app-icons name="error" width="20" height="20" fillColor="#6A49FA"></app-icons>
        <span *transloco="let t;">{{t('Your_creators_profile_rejected')}}</span>
      </div>
      <span class="notification_body-date">{{updateAt}}</span>
    </div>
    <app-icons (click)="deleteMe()" class="notification_delete" name="trash" width="25" height="25" fillColor="#6A49FA"></app-icons>
  </div>
</ng-template>
<ng-template #toast>
  <div class="notification toast">
    <div class="notification_type">
      <app-icons name="error" width="20" height="20" fillColor="#6A49FA"></app-icons>
    </div>
    <div class="notification_body">
      <div class="notification_body-text">
        <span *transloco="let t;">{{t('Your_creators_profile_rejected')}}</span>
      </div>
    </div>
    <app-icons name="close_1" class="notification_delete" width="40" height="40" fillColor="#DEDEEB" (click)="onRead.emit(data.id)"></app-icons>
  </div>
</ng-template>
