import {
  AfterContentChecked,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  NgZone,
  OnDestroy,
  Output
} from "@angular/core";
import {NotificationsRepositoryService} from "../../../../repository/notifications-repository.service";
import moment from "moment";
import {StorageDataKey, StorageService} from "../../../../service/storage.service";
import {Router} from "@angular/router";
import {TNotification} from "@prestinly/core-lib/lib/types/notifications/notification.type";
import {
  INotificationsNewPostNotification
} from "@prestinly/core-lib/lib/types/notifications/content/new-post.notification.interface";
import {
  INotificationsNewPostCommentNotification
} from "@prestinly/core-lib/lib/types/notifications/content/new-post-comment.notification.interface";
import {
  INotificationsDonatedNotification
} from "@prestinly/core-lib/lib/types/notifications/purchases/donated.notification.interface";
import {
  INotificationsMessagePurchasedNotification
} from "@prestinly/core-lib/lib/types/notifications/purchases/message-purchased.notification.interface";
import {
  INotificationsPostPurchasedNotification
} from "@prestinly/core-lib/lib/types/notifications/purchases/post-purchased.notification.interface";
import {
  INotificationsChangedSubscriptionFromFreeToPaidNotification
} from "@prestinly/core-lib/lib/types/notifications/subscription/changed-subscription-from-free-to-paid.notification.interface";
import {
  INotificationsChangedSubscriptionFromPaidToFreeNotification
} from "@prestinly/core-lib/lib/types/notifications/subscription/changed-subscription-from-paid-to-free.notification.interface";
import {
  INotificationsNewSubscriberNotification
} from "@prestinly/core-lib/lib/types/notifications/subscription/new-subscriber.notification.interface";
import {
  INotificationsInPostCommentTagNotification
} from "@prestinly/core-lib/lib/types/notifications/tags/in-post-comment-tag.notification.interface";
import {
  INotificationsInPostTagNotification
} from "@prestinly/core-lib/lib/types/notifications/tags/in-post-tag.notification.interface";
import {INotificationUser} from "@prestinly/core-lib/lib/types/notifications/user.interface";
import {EUserImageType} from "@prestinly/core-lib/lib/enums/user-image-type.enum";
import {INotificationsUserImages} from "@prestinly/core-lib/lib/types/notifications/user-images.interface";
import {ChatService} from "../../../../service/chat.service";

@Component({
  selector: 'app-notification',
  template: ``
})
export class AppNotification implements AfterContentChecked, OnDestroy {
  observer!: IntersectionObserver;
  declare notification: ElementRef;
  @Input() outlet: 'toast' | 'list' = 'list';
  @Output() onDelete = new EventEmitter<string>();
  @Output() onRead = new EventEmitter<string>();
  private repository = inject(NotificationsRepositoryService);
  private ngZone = inject(NgZone);
  declare data: TNotification;

  get updateAt() {
    const mDate = moment(this.data.created).endOf('day').valueOf();
    const today = moment().endOf('day').valueOf();
    const yesterday = moment().add(-1, 'day').endOf('day').valueOf();
    if (mDate == today) {
      return 'Today';
    }
    if (mDate == yesterday) {
      return 'Yesterday';
    }
    return moment(this.data.created).format('DD MMM HH:mm')
  }

  readMe() {
    this.onRead.emit(this.data.id);
  }
  deleteMe() {
    this.repository.delete(this.data.id).then(_ => {
      this.onDelete.emit(this.data.id);
    });
  }
  getFull(id: string) {}

  ngAfterContentChecked(): void {
    this.ngZone.runOutsideAngular(() => {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((e) => {
         if (!this.data.isReadByUser && e.isIntersecting) {
           this.readMe();
         }
        });
      });
      if (this.notification) {
        this.observer.observe(this.notification.nativeElement);
      }
    });
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }
}
@Component({
  selector: 'app-notification-from',
  template: ``
})
export class AppNotificationFrom extends AppNotification {
  declare data: INotificationsNewPostNotification
    | INotificationsNewPostCommentNotification
    | INotificationsDonatedNotification
    | INotificationsMessagePurchasedNotification
    | INotificationsPostPurchasedNotification
    | INotificationsChangedSubscriptionFromFreeToPaidNotification
    | INotificationsChangedSubscriptionFromPaidToFreeNotification
    | INotificationsNewSubscriberNotification
    | INotificationsInPostCommentTagNotification
    | INotificationsInPostTagNotification;

  storage = inject(StorageService);
  zone = inject(NgZone);
  router = inject(Router);
  chat = inject(ChatService);

  get myId() {
    return this.storage.getLocalJsonData(StorageDataKey.apiUser).id;
  }

  get hasUser(): boolean {
    return this.data.fromUser != null;
  }

  get from(): INotificationUser | null {
    return this.data.fromUser;
  }

  get fromAvatar(): INotificationsUserImages | null {
    const images = this.from?.images.filter(data => {
      return data.imageType == EUserImageType.AVATAR
    });
    if (images?.length) {
      return images[0];
    }
    return null;
  }

  get displayName(): string {
    if (!this.hasUser) {
      return 'Deleted user';
    }
    return this.from!.displayName;
  }

  navigateToProfile() {
    if (!this.hasUser || !this.from.isCreator) return;
    this.zone.run(() => {
      this.router.navigate([`profile/${this.from.id}`]);
    });
  }

  sendMessage(userId: string) {
    this.chat.checkIsExist(userId).then(id => {});
  }
}
