<ng-container *ngTemplateOutlet="outlet == 'list' ? list : toast"></ng-container>
<ng-template #list>
  <div class="notification" #notification>
    <div class="notification_status" [ngClass]="{'isRead': data.isReadByUser}"></div>
    <app-profile-avatar (click)="navigateToProfile()" size="chat" [url]="fromAvatar?.url"></app-profile-avatar>
    <div class="notification_body">
      <app-user-name (click)="navigateToProfile()" [displayName]="displayName" [nickname]="this.from?.nickname" [kycVerified]="this.from?.isCreator && this.from?.kycVerified"></app-user-name>
      <div class="notification_body-text">
        <app-icons name="price" width="20" height="20" fillColor="#6A49FA"></app-icons>
        <span *transloco="let t;">{{t('Changed_subscription_price_from_free_to')}} <span class="notification_link">${{data.amount}}</span></span>
      </div>
      <span class="notification_body-date">{{updateAt}}</span>
    </div>
    <app-icons (click)="deleteMe()" class="notification_delete" name="trash" width="25" height="25" fillColor="#6A49FA"></app-icons>
  </div>
</ng-template>
<ng-template #toast>
  <div class="notification toast">
    <div class="notification_type">
      <app-icons name="price" width="20" height="20" fillColor="#6A49FA"></app-icons>
    </div>
    <app-profile-avatar (click)="navigateToProfile()" size="notification" [url]="fromAvatar?.url"></app-profile-avatar>
    <div class="notification_body">
      <app-user-name (click)="navigateToProfile()" [displayName]="displayName" [nickname]="this.from?.nickname" [kycVerified]="this.from?.isCreator && this.from?.kycVerified"></app-user-name>
      <div class="notification_body-text">
        <span *transloco="let t;">{{t('Changed_subscription_price_from_free_to')}} <span class="notification_link">${{data.amount}}</span></span>
      </div>
    </div>
    <app-icons name="close_1" class="notification_delete" width="40" height="40" fillColor="#DEDEEB" (click)="onRead.emit(data.id)"></app-icons>
  </div>
</ng-template>
